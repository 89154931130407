<script setup>
import {useUserStore} from "~/store/userStore";

const userStore = useUserStore()
</script>

<template>
  <div class="adm-info g-16">
    <div class="adm-btn" @click="userStore.toggleShowAdmin()">
      i
    </div>
  </div>
</template>

<style scoped lang="scss">
.adm-info {
  position: fixed;
  right: 36px;
  bottom: 100px;
  z-index: 9;
}

.adm-btn {
  width: 42px;
  max-height: 42px;
  color: #fff;
  background: #fcc000;
  font-size: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, .08);
  cursor: pointer;
  z-index: 999999999999999;
}
</style>
